<template>
  <div
    v-if="itemList.length"
    class="text-image-tile-slider content-container-template"
  >
    <div
      class="text-image-tile-slider__grid"
      :class="gridLayoutClass"
    >
      <TextImageTileSliderItem
        v-for="(item, index) in itemList"
        :key="index"
        :item="item"
      />
    </div>

    <UiSwiper
      :options="swiperOptions"
      class="text-image-tile-slider__slider"
    >
      <SwiperSlide
        v-for="item in itemList"
        :key="item.id"
      >
        <TextImageTileSliderItem :item="item" />
      </SwiperSlide>
    </UiSwiper>
  </div>
</template>

<script setup lang="ts">
import { Navigation } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'
import { SwiperSlide } from 'swiper/vue'
import { computed } from 'vue'

import { UiSwiper } from '~ui/components'
import type { UiCeProductBoxesProps } from '~ui/types'

import TextImageTileSliderItem from './TextImageTileSliderItem.vue'

const props = defineProps<UiCeProductBoxesProps>()

const gridLayoutClass = computed(() => {
  return props.itemList.length >= 4 && props.itemList.length % 2 === 0
    ? 'text-image-tile-slider__grid--even'
    : 'text-image-tile-slider__grid--odd'
})

const swiperOptions = computed(
  (): SwiperOptions => ({
    modules: [Navigation],
    slidesPerView: 'auto',
    spaceBetween: 16
  })
)
</script>

<style lang="scss">
.text-image-tile-slider {
  padding-block: rem(24px);
  position: relative;
  overflow: hidden;
  isolation: isolate;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: z-index(below);
    opacity: 0.4;
    background-color: var(--color__border-color);
  }

  &.content-container-template {
    padding-inline: rem($container-padding);

    @include media-query(sm) {
      padding-inline: rem(24px);
    }
  }

  @include media-query(sm) {
    border-radius: rem(8px);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: rem(24px);

    @include media-query(max sm) {
      display: none;
    }

    & > * {
      grid-column: span 2;
    }

    &--even > * {
      grid-column: span 3;
    }
  }

  &__slider.swiper {
    overflow: visible;

    @include media-query(sm) {
      display: none;
    }

    & .swiper-slide {
      $slide-width: rem(720px);

      width: min(95%, $slide-width);
      max-width: $slide-width;
    }
  }
}
</style>
